import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import * as actions from "../state/actions/keymans"
import {
  INDUSTRIES_PER_PAGE,
  KEYMAN_RANK_TYPE,
  TOP_N_ITEMS,
} from "../constants/constants"
import ListLayout from "../components/common/list-layout"
import IndustryItem from "../components/common/industry-item"
import { Location } from "@reach/router"
import { getQueryVariable } from "../utils/str"
import Ranking from "../components/common/ranking"

const mapStateToProps = (state, ownProps) => {
  return {
    industries: state.industries.industries.toIndexedSeq().toArray(),
    pageInfo: state.industries.pageInfo_industry,
    isLoading: state.industries.isLoadingIndustries,
    isLoadingIndustryRank: state.industries.isLoadingIndustryRank,
    letterRankStats: state.industries.letterRankStats,
    reactionRankStats: state.industries.reactionRankStats,
    shoudanRankStats: state.industries.shoudanRankStats,
    keymansTotal: state.global.keymansTotal,
    corporationsTotal: state.global.corporationsTotal,
    updateKeymans: state.global.updateKeymans.toIndexedSeq().toArray(),
    isLoadingUpdateKeymans: state.global.isLoadingUpdateKeymans,
    keymanCountYstd: state.global.keymanCountYstd,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getKeymansIndustries: data => dispatch(actions.getKeymansIndustries(data)),
    getKeymansYesterday: () => dispatch(actions.getKeymansYesterday()),
    getKeymansTotal: () => dispatch(actions.getKeymansTotal()),
    getKeymansCount: () => dispatch(actions.getKeymansCount()),
    getRankJsicL: data => dispatch(actions.getRankJsicL(data)),
  }
}

const styles = theme => ({})

class Industries extends React.Component {
  componentDidMount() {
    this.props.getKeymansCount()
    this.props.getKeymansTotal()
    this.props.getKeymansYesterday()
    this.props.getRankJsicL({ top_n: TOP_N_ITEMS })
  }

  getQuery = (props, query = {}) => {
    return {
      paging: 1,
      per_page: INDUSTRIES_PER_PAGE,
      page: 1,
      ...getQueryVariable(props.location.search, ["page"]),
      ...query,
    }
  }

  render() {
    const {
      industries,
      isLoading,
      pageInfo,
      keymanCountYstd,
      keymansTotal,
      corporationsTotal,
      letterRankStats,
      reactionRankStats,
      shoudanRankStats,
      updateKeymans,
      isLoadingUpdateKeymans,
    } = this.props

    return (
      <ListLayout
        name="企業の業界"
        title="企業の業界一覧"
        ItemComponent={IndustryItem}
        items={industries}
        isLoading={isLoading}
        perPage={INDUSTRIES_PER_PAGE}
        pageInfo={pageInfo}
        getItems={(data, overwrite) =>
          this.props.getKeymansIndustries(
            this.getQuery(this.props, data),
            overwrite
          )
        }
        keymanCountYstd={keymanCountYstd}
        updateKeymans={updateKeymans}
        isLoadingUpdateKeymans={isLoadingUpdateKeymans}
        keymansTotal={keymansTotal}
        corporationsTotal={corporationsTotal}
        path="industries"
        isInitialized={true}
      >
        <Ranking
          title="業界別レター送付率ランキング"
          id="letter-rank"
          path="/industries"
          type={KEYMAN_RANK_TYPE.LETTER}
          items={letterRankStats}
        />

        <Ranking
          title="業界別レター確認率ランキング"
          id="reaction-rank"
          path="/industries"
          type={KEYMAN_RANK_TYPE.REACTION}
          items={reactionRankStats}
        />
        <Ranking
          title="業界別レター商談達成率ランキング"
          id="shoudan-ranking"
          path="/industries"
          type={KEYMAN_RANK_TYPE.SHOUDAN}
          items={shoudanRankStats}
        />
      </ListLayout>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => (
    <Location>
      {locationProps => <Industries {...locationProps} {...props} />}
    </Location>
  ))
)
